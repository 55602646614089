import fichier from "@/utils/fichier.js";
import PdfLecteur from "@/components/pdfLecteur/pdfLecteur.vue";
import communLangage from "@/commun/commun.langage.js";

export default {
  name: "MentionsLegales",

  components: {
    PdfLecteur,
  },

  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },

    langage() {
      return communLangage.langage;
    },
  },

  data() {
    return {
      emplacementFichier: null,
    };
  },

  watch: {
    langage: function () {
      this.emplacementFichier = fichier.recupererEmplacementMentionsLegales();
    },
  },

  created() {
    this.emplacementFichier = fichier.recupererEmplacementMentionsLegales();
  },
};
