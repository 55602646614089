<template>
     <div>
          <PdfLecteur
          v-if="emplacementFichier"
      :fichierEmplacement="emplacementFichier"
      class="p-3 w-100"
      style="flex-basis: 70%"
    />
    </div>
</template>

<script src="./mentionsLegales.js"></script>